@import 'mixins';

@font-face {
  font-family: $font-name;
  src: url('/fonts/CircularStd-Book.otf');
  font-weight: $font-weight--book;
  font-style: normal;
}

@font-face {
  font-family: $font-name;
  src: url('/fonts/CircularStd-BookItalic.otf');
  font-weight: $font-weight--book;
  font-style: italic;
}

@font-face {
  font-family: $font-name;
  src: url('/fonts/CircularStd-Medium.otf');
  font-weight: $font-weight--medium;
  font-style: normal;
}

@font-face {
  font-family: $font-name;
  src: url('/fonts/CircularStd-MediumItalic.otf');
  font-weight: $font-weight--medium;
  font-style: italic;
}

@font-face {
  font-family: $font-name;
  src: url('/fonts/CircularStd-Bold.otf');
  font-weight: $font-weight--bold;
  font-style: normal;
}

@font-face {
  font-family: $font-name;
  src: url('/fonts/CircularStd-BoldItalic.otf');
  font-weight: $font-weight--bold;
  font-style: italic;
}

@font-face {
  font-family: $font-name;
  src: url('/fonts/CircularStd-Black.otf');
  font-weight: $font-weight--black;
  font-style: normal;
}

@font-face {
  font-family: $font-name;
  src: url('/fonts/CircularStd-BlackItalic.otf');
  font-weight: $font-weight--black;
  font-style: italic;
}

h1 {
  @include font-size(3.2rem, 6.4rem);
  margin: 0;
  font-weight: $font-weight--black;
  line-height: 1.125;
}

h2 {
  @include font-size(3.2rem, 4.8rem);
  margin: 0;
  font-weight: $font-weight--black;
  line-height: 1.167;
}

h3 {
  @include font-size(2.6rem, 4rem);
  margin: 0;
  font-weight: $font-weight--bold;
  line-height: 1.2;
}

h4 {
  @include font-size(2.4rem, 3.2rem);
  margin: 0;
  color: $cl-secondary--darker;
  font-weight: $font-weight--bold;
  line-height: 1.25;
}

h5 {
  @include font-size(1.8rem, 2.4rem);
  margin: 0;
  color: $cl-black--light;
  font-weight: $font-weight--bold;
  line-height: 1.33;
}

h6 {
  @include font-size(1.6rem, 1.8rem);
  margin: 0;
  font-weight: $font-weight--bold;
  line-height: 1.778;
}

p {
  margin-top: 0;
}

ol,
ul {
  margin-bottom: 1.6rem;
}

.tag {
  color: $cl-grey;
  font-size: 1.6rem;
  font-weight: $font-weight--bold;
  letter-spacing: .1rem;
  text-transform: uppercase;
}
