@import 'styles/colors';
@import 'styles/variables';
@import 'styles/grid';

.card {
  @extend .col-m6;
  max-width: 48rem;
  margin: 0 auto 3.2rem;
  padding: 1.6rem;
  background-color: $cl-white;
  border: .1rem solid $cl-grey--light;
  border-radius: .6rem;
  color: $cl-black--light;
  box-shadow: .0rem .1rem .2rem rgba($cl-shadow, .05), .0rem .3rem 1.2rem rgba($cl-shadow, .05);

  @include min(m) {
    position: absolute;
    right: 0;
    margin: 0 0 0 auto;
    padding: 3.2rem;
  }

  @include max(m) {
    margin-top: 4.8rem;
    margin-bottom: -40rem;
  }
}

.card-title {
  font-weight: $font-weight--bold;
  font-size: 2.4rem;
  line-height: 1.33;
  margin-bottom: .8rem;
}

.card-text {
  font-size: 1.8rem;
  line-height: 1.56;
}

.card-table {
  width: 100%;
  margin-top: 3.2rem;
  table-layout: auto;

  > div:first-child {
    font-weight: $font-weight--bold;

    .table-cell--no-padding {
      padding-bottom: .8rem;
    }
  }

  .table-cell--no-padding {
    padding: 0 0 1.6rem;
    width: 100%;

    label {
      padding: 0;
    }

    &:first-child {
      label {
        width: 100%;

        div {
          width: 100%;

          select {
            @include max(s) {
              width: 5.5rem;
            }
          }

          span {
            width: 13.4rem;

            @include min(s) {
              width: 14.4rem;
            }
          }
        }
      }
    }

    &:last-child {
      width: 13.2rem;

      @include min(s) {
        width: 18.4rem;
      }

      label {
        width: 100%;

        div {
          width: 100%;

          select {
            @include max(s) {
              width: 5.5rem;
            }
          }
        }
      }
    }
  }

  .row--padding-left {
    padding-left: .8rem;

    @include min(s) {
      padding-left: 2.4rem;
    }
  }
}

.card-total {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: .8rem;
  justify-content: space-between;
}

.card-total__title {
  font-size: 1.8rem;
  line-height: 1.56;
  font-weight: $font-weight--bold;
}

.card-total__value {
  display: flex;
  font-size: 4.8rem;
  line-height: 1.17;
  font-weight: $font-weight--black;
  color: $cl-secondary--darker;

  .currency {
    margin-right: .8rem;
    font-size: 2.4rem;
    line-height: 1.67;
    font-weight: $font-weight--book;
  }
}

.card-total__bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 3.2rem;
  width: 100%;
}
