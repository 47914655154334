@import 'styles/colors';
@import 'styles/variables';
@import 'styles/mixins';

.container {
  @include max(s) {
    height: 100vh;
  }
}

.title {
  text-align: center;
  padding: 3.2rem 0;
  font-size: 3.2rem;
  
  @include min(m) {
    width: 35.2rem;
  }
}

.shareBox {
  display: flex;
  flex-direction: column;
  a{
    display: flex;
    align-items: center;
    height: 6.4rem;
    box-shadow: inset 0px -1px 0px $cl-grey--lightest;
    font-size: 1.8rem;
    color: $cl-secondary--darker;
    text-decoration: none;
    img {
      width: 2.4rem;
      margin-right: 1.6rem;
    }
  }
  a:last-child{
    box-shadow: none;
  }
}
