@import './Form.module.scss';

.label {
  position: relative;

  background-color: white;
  :global .overlayWrapper .DayPicker {
    left: 0;
    transform: none;
  }
}

.secondary {
  .container {
    @include max(s) {
      border-radius: .6rem;
    }
  }
}

.tertiary {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include max(m) {
    flex-shrink: 0;
    width: 25vw;
    min-width: 10rem;
  }

  input {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0;
    text-transform: lowercase;
    &::placeholder {
      text-transform:initial;
    }
  }

  .container {
    @include max(s) {
      padding-left: .8rem;
      padding-right: 0;
      background: $cl-white;
    }

    @include max(m) {
      height: 4.8rem;
      border: none;
      border-right: .1rem solid $cl-grey--lighter;
      border-radius: 0;
    }
  }

  :global .DayPicker {

    @include max(s) {
      position: fixed;
      top: 6.4rem + 4.8rem;
      left: 0;
      right: 0;
      width: 100vw;
      transform: translate3d(0, 0, 0);
      border-radius: 0;
    }
  }
}

.availability {
  position: relative;

  :global .DayPicker {
    margin-top: 3.2rem;

    @include max(s) {
      width: auto;
      left: 0;
      transform: translateX(0);
    }
  }
}

.overlay {
  display: block;
  &::after {

    .overlayBackground & {
      content: "";
      z-index: 10;
      position: fixed;
      top: 8rem;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($cl-black, .4);
      pointer-events: none;
    }
  }
}

.container {
  @extend .input;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4.8rem;
  width: 100%;
  margin: 0;
  position: relative;
  padding-left: 4rem;
  border-right: none;
  background: url("/images/icons/calendar.svg") 1.5rem center $cl-white no-repeat;

  input {
    outline: none;
  }
}

.wrapper {
  display: block;
}

.clearDate {
  position: absolute;
  right: 0;
  top: 2.8rem;
  display: flex;
  display: none;
  height: 4.8rem;

  button {
    margin-right: 1.5rem;
    cursor: pointer;

    img {
      display: block;
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}
