@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/grid";
@import "@website/Modal/Modal.module.scss";

.modal {
  background-color: $cl-black--light;
  position: fixed;
  display: flex;
  align-items: center;
  max-width: $max-container-width;
  z-index: 10;
  transition: {
    property: transform, opacity, visibility;
    duration: 300ms;
  }
  padding: 1.8rem 3.2rem;
  bottom: 3.2rem;

  p {
    font-size: 1.8rem;
    line-height: 1.6;
    color: white;
    margin-right: 3.2rem;
    margin-left: 3.2rem;
  }
}

.modal--is-visible {
  transform: scale(1);
}

.modal--is-hidden {
  opacity: 0;
  visibility: hidden;
}

.modal-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close-icon {
  stroke: white;
}
