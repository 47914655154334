
@import '~swiper/swiper.scss';

.swiper-button-prev,
.swiper-button-next {
  cursor: pointer;
  position: absolute;
  z-index: $zIndex--middle;
  top: calc(50% - 2.4rem);
  width: 4.8rem;
  height: 4.8rem;
  background: {
    color: $cl-white;
    repeat: no-repeat;
    position: center;
  }
  box-shadow: 0 .1rem .4rem rgba($cl-black--lightest, .1);
  border: .1rem solid $cl-grey--dark;
  border-radius: 50%;
}

.swiper-button-prev {
  left: 1.6rem;
  background-image: url('/images/icons/arrow-left.svg');
}

.swiper-button-next {
  right: 1.6rem;
  background-image: url('/images/icons/arrow-right.svg');
}

.swiper-pagination-bullet {
  &.secondary-darker {
    background-color: $cl-secondary--darker;
    opacity: 1;
  } 
}