@import 'styles/colors';
@import 'styles/variables';
@import 'styles/mixins';

.overlayWrapper {
  .DayPicker {
    z-index: 10;
    max-width: 32.8rem;
    border-radius: 1.2rem;
    position: absolute;
    box-shadow: 0 .1rem .4rem rgba($cl-shadow, .08), 0 .6rem 1.2rem rgba($cl-shadow, .1);
  }

  .dropdownOnly {
    display: block;
  }
}

.DayPicker-wrapper {
  padding-bottom: 0;
}

.DayPicker {
  color: $cl-black--light;
  font-size: 1.6rem !important;
  background-color: $cl-white;
  border-radius: 1.2rem;
  user-select: none;

  .DayPicker-Day {
    cursor: pointer;

    &:not(&--selected) {
      &:hover {
        background-color: $cl-primary--lightest;
      }
    }

    &--outside {
      color: $cl-grey--dark;
    }

    &--disabled {
      color: $cl-grey--lighter;
      pointer-events: none;
    }

    &--selected {
      background-color: $cl-primary;
      color: $cl-white;
    }

    &--today:not(&--selected) {
      box-shadow: 0 0 0 .1rem $cl-primary inset;
      color: $cl-primary;
    }
  }

  .DayPicker-Caption {
    height: 2.7rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    font-weight: $font-weight--bold;
    margin-bottom: .8rem;

    > div {
      height: 100%;
    }
  }

  .DayPicker-Month {
    margin: 2.4rem;
  }

  .DayPicker-Day {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.6rem;
    height: 3.6rem;
    margin: .2rem;
    border-radius: 1.2rem;
  }

  .DayPicker-Weekdays,
  .DayPicker-Week {
    display: flex;
    justify-content: center;
  }

  .DayPicker-Weekday {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    color: $cl-black--lighter;
    font-size: 1.6rem;
    font-weight: $font-weight--bold;

    abbr {
      text-decoration: none;
    }
  }

  .DayPicker-Body {
    display: flex;
    flex-direction: column;
  }

  .DayPicker-Week {
    display: flex;
  }

  .DayPicker-NavBar {
    position: relative;
    width: 100%;
    max-width: calc(100% - 5.6rem);
    margin: 0 auto;
  }

  .DayPicker-NavButton {
    position: absolute;
    width: 1.6rem;
    height: 2.7rem;
    margin: 0;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .DayPicker-NavButton--prev {
    left: 0;
    padding-left: 7rem;
    background: {
      image: url('/images/icons/chevron-left.svg');
      size: auto 1.2rem;
      position: 1rem .6rem;
    }
  }

  .DayPicker-NavButton--next {
    padding-right: 7rem;
    right: 0;
    background: {
      image: url('/images/icons/chevron-right.svg');
      size: auto 1.2rem;
      position: calc(100% - 1rem) .6rem;
    }
  }

  @include max(m) {
    width: 100%;
    border: 0;
  }
}
