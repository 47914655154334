@import 'styles/colors';
@import 'styles/variables';
@import 'styles/grid';
.search {
  z-index: 10;
  position: relative;
  display: flex;
  flex-grow: 1;
  max-width: 100%;
  justify-content: center;

  &.detailed {
    .mobile {
      display: none;
    }
    .desktop {
      display: flex;
      box-shadow: none;
      border-radius: 0;

      >div,
      >label {
        @include max(m) {
          width: 100%;
        }
        >button,
        >div {
          border-top: .1rem solid $cl-grey--lightest;
          border-bottom: .1rem solid $cl-grey--lightest;
          >input {
            width: 13rem;
          }
        }
      }
      >div {
        width: max-content;
        >input {
          box-shadow: none;
          border: .1rem solid $cl-grey--lightest;
        }
      }
      >div:first-child {
        @include max(m) {
          width: 100%;
        }
      }

      >:not(:last-child){
        margin-bottom: .8rem;
      }
      @include max(m) {
        flex-direction: column;
      }
    }
  }
}

.primary {
  flex-grow: 0;
  // margin-left: 9.4rem;
  .submit {
    border-radius: 0 1.2rem 1.2rem 0;

    @include max(s) {
      width: 4.8rem;
      span {
        display: none;
      }
    }
  }

  @include max(m) {
    flex-grow: 1;
    border-top: .1rem solid $cl-grey--border;
    border-bottom: .1rem solid $cl-grey--border;
  }
  @include max(m) {
    border-bottom: none;
  }
}

.secondary {

  > div,
  > label {

    @include max(s) {
      margin-bottom: .8rem;
    }
  }

  @include max(s) {
    flex-wrap: wrap;
  }

  .submit {
    align-items: center;
    height: 4.8rem;
    border-radius: 0 .6rem .6rem 0;
  }

  .rounded {

    @include max(s) {
      width: 100%;
      border-radius: .6rem;
    }
  }
}

.desktop {
  display: flex;
  box-shadow: 0px 1px 4px rgba($cl-shadow, .08), 0px 6px 12px rgba($cl-shadow, .1);
  border-radius: 1.2rem;
  margin: 0 auto;
  max-width: 35.2rem;
  width: 100vw;
  transition: max-width 700ms;

  .button-holder {
    max-width: 0;
    overflow: hidden;
    border-radius: 0 1.2rem 1.2rem 0;
    opacity: 0;
    transition: max-width 350ms, opacity 200ms, overflow 700ms;
  }

  &.details-visible {
    max-width: 89.7rem;

    @include max(l) {
      max-width: 64rem;
    }

    .button-holder {
      max-width: 10.8rem;
      opacity: 1;
      transition-delay: 350ms;
    }
  }

  @include max(m) {
    display: none;
  }
}

.inputs-container {
  display: flex;
  overflow: hidden;
  width: 100%;
  border-radius: 1.2rem;
  max-width: 100%;
  transition: overflow 700ms;

  > div,
  > label {
    width: 100%;
    max-width: 0;
    flex-grow: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-width 700ms, opacity 700ms, overflow 700ms;

    > input,
    > button,
    > div {
      border: 0;
    }
  }

  > *:not(:first-child) {
    border-left: .1rem solid $cl-grey--lighter;
  }

  > div:first-child {
    max-width: 100%;
    flex-grow: 1;
    opacity: 1;
  }

  .details-visible & {
    overflow: visible;
    max-width: calc(100% - 10.8rem);

    > div,
    > label {
      flex-grow: 0;
      max-width: 33.3%;
      opacity: 1;
      overflow: visible;
      transition-delay: 200ms;
    }
  }
}

.mobile {
  display: none;
  border-bottom: none;
  background-color: white;
  flex-grow: 1;

  .header {
    align-items: center;
    button {
      display: block;
      position: absolute;
      width: 3.2rem;
      height: 3.2rem;
      border: none;
      box-shadow: 0px 1px 4px 0px #090c181a;
      border-radius: 50%;
      padding: 0;
      margin-left: 1.6rem;
      text-align: center;
      img {
        margin-right: .2rem;
        margin-top: .2rem;
      }
    }
    span {
      color: $cl-black--light;
      margin-left: auto;
      margin-right: auto;
      padding: 1rem 0;
      font-size: 1.8rem;
      line-height: 3.2rem;
      font-weight: bold;
    }
  }

  .navigation {
    display: flex;
    flex-direction: row;
    border-top:  .1rem solid $cl-grey--lightest;
    justify-content: space-between;
    padding: 1.6rem;
    background: white;
    margin-bottom: -1rem;
    border-bottom-right-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem;
    button {
      width: 50%;
      justify-content: center;
      border-radius: 1.2rem;
    }
  }

  >div:first-child {
    width: 100%;
    display: flex;

    input {
      width: 100%;
      border-radius: 1.2rem;
      border: none;
      box-shadow: 0px 6px 12px 0px #090C181A,
                  0px 1px 4px 0px #090C1814;
    }
  }

  :global .DayPicker {
    border-top:  .1rem solid $cl-grey--lightest;
    border-radius: 0;

    .DayPicker-NavBar {
      max-width: 28rem;
    }

    @include max(m) {
      @media (orientation: landscape) {
        height: 14rem;
        overflow-y: scroll;
      }
    }
  }

  @include max(m) {
    display: flex;
    flex-direction: column;
  }
}

.overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 8rem;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  transition: visibility 500ms, opacity 500ms;
  background-color: rgba($cl-black, .4);
}

.overlayVisible {
  visibility: visible;
  opacity: 1;
}
