@import 'styles/variables';
@import 'styles/grid';
@import 'styles/colors';
@import 'styles/mixins';

.badge {
  display: inline-block;
  margin: 0 1.6rem 0 .8rem;
  padding: .1rem .6rem;
  border-radius: .6rem;
  font-size: 1.4rem;
  font-weight: $font-weight--book;
  line-height: 1.57;
  color: $cl-secondary;
  background-color: $cl-secondary--lighter;
}

.badge--published,
.badge--active {
  background-color: rgba($cl-success, .2);
  color: $cl-success;
}

.badge--archived,
.badge--expired {
  background-color: rgba($cl-warning, .2);
  color: $cl-warning;
}

.badge--deleted {
  background-color: rgba($cl-error, .2);
  color: $cl-error;
}
