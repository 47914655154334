@import 'styles/colors';
@import 'styles/variables';
@import 'styles/grid';
@import 'styles/button';

.modalTitle {
  margin-bottom: .8rem;
  padding-right: 2.4rem;
  font-size: 2.4rem;
  font-weight: $font-weight--bold;
  line-height: 1.33;
  color: $cl-secondary--darker;
}

.modalTitleSecondary {
  margin-bottom: 3.2rem;
  color: $cl-secondary--darker;
  text-align: center;
}

.modalText {
  margin-bottom: 1.6rem;
  font-size: 1.8rem;
  line-height: 1.78;
  color: $cl-black--light;

  &:last-child {
    margin-bottom: 0;
  }

  + .formRow {
    margin-top: 3.2rem;
  }
}

.formInput {
  appearance: none;
  box-sizing: border-box;
  max-height: 4.8rem;
  padding: 1.1rem 1.5rem;
  border: .1rem solid $cl-grey--light;
  border-radius: .6rem;
  font-size: 1.6rem;
  line-height: 1.5;
  background: $cl-white;
}

.formCheckbox {
  appearance: none;
  position: relative;
  overflow: hidden;
  width: 1.6rem;
  height: 1.6rem;
  margin: -.3rem 1.2rem 0 0;
  border: .1rem solid $cl-grey--darker;
  border-radius: .3rem;
  background-color: $cl-white;
  vertical-align: top;
  transition: {
    property: border-color, box-shadow;
    duration: 200ms;
  };

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/images/icons/check.svg') 50% no-repeat;
  }

  &:checked {
    border-color: $cl-secondary;
    box-shadow: 0 0 0 .8rem $cl-secondary inset;
  }
}

.formInputSingleLine {
  flex-grow: 1;

  @include min(s) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
}

.formButtonSingleLine {
  @include max(s) {
    margin-top: 1.6rem;
  }

  @include min(s) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.formRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6rem;

  &:not(.formRowCheckbox) {
    + .formRowCheckbox {
      margin-top: 3.2rem;
    }
  }
}

.formRowCheckbox {
  margin-bottom: 1.4rem;
}

.formRowLast {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 3.2rem 0 0;

  .errorMessage,
  .successMessage {
    margin-top: .6rem;
  }
}

.formRowSingleLine {
  display: flex;

  @include max(s) {
    flex-direction: column;
  }
}

.formRowLabel {
  color: $cl-grey--darker;
  margin-bottom: .4rem;
  line-height: 1.5;
}

.formRowLabelCheckbox {
  display: flex;
  align-items: center;
  min-height: 3.2rem;
  line-height: 1.78;
  color: $cl-black--lighter;
  cursor: pointer;
}

.errorMessage {
  display: block;
  margin-top: 1.6rem;
  color: $cl-error;
}

.successMessage {
  display: block;
  margin-top: 1.6rem;
  color: $cl-success;
}
