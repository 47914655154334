@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/grid';
@import '../Modal/Modal.module.scss';

.modal {
  z-index: $zIndex--modal;
  position: fixed;
  display: flex;
  align-items: center;
  transition: {
    property: transform, opacity, visibility;
    duration: 300ms;
  };

  @include min(s) {
    bottom: 3.2rem;
  }

  @include max(s) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    bottom: 0;
  }

  p {
    font-size: 1.6rem;
    line-height: 1.5;

    @include min(s) {
      margin-right: 3.2rem;
    }

    @include max(s) {
      margin-bottom: 2.4rem;
    }
  }

  @include max(s) {
    flex-direction: column;
  }

  button {
    text-align: center;

    @include max(s) {
      width: 100%;
    }
  }
}

.modal--is-visible {
  transform: scale(1);
}

.modal--is-hidden {
  opacity: 0;
  visibility: hidden;
}
