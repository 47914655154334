@import 'styles/variables';
@import 'styles/colors';

.default {
  appearance: none;
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  padding: 1.5rem;
  color: $cl-white;
  font-size: 1.6rem;
  font-weight: $font-weight--bold;
  line-height: 1;
  text-decoration: none;
  background: none;
  border: .1rem solid rgba($cl-shadow, .1);
  border-radius: 1.2rem;
  transition: background-color 200ms, color 200ms, border-color 200ms;
}

.primary {
  background-color: $cl-primary;

  &:hover,
  &:focus {
    background-color: $cl-primary--light;
  }

  &:active {
    background-color: $cl-primary;
  }
}

.secondary {
  background-color: $cl-secondary--darker;

  &:hover,
  &:focus {
    background-color: $cl-secondary--dark;
  }

  &:active {
    background-color: $cl-secondary--darker;
  }
}

.inverted {
  color: $cl-secondary--darker;
  background-color: $cl-white;

  &:hover,
  &:focus {
    background-color: $cl-grey--lightest;
  }

  &:active {
    background-color: $cl-white;
  }
}

.spaced {
  margin: 0 .8rem;
}

.text {
  color: $cl-secondary;
  font-weight: $font-weight--book;
  padding: 0;
  box-shadow: none;
  border: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}
