@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/grid';
@import '../Modal/Modal.module.scss';

.modal {
  position: fixed;
  z-index: $zIndex--modal;
  transition: {
    property: transform, opacity, visibility;
    duration: 300ms;
  };

  @include min(s) {
    bottom: 3.2rem;
  }

  @include max(s) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    bottom: 0;
  }
}

.modal--is-visible {
  transform: scale(1);
}

.modal--is-hidden {
  opacity: 0;
  visibility: hidden;
}
