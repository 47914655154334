.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;

  > .container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    position: relative;
  }
}

.background--grey {
  background-color: $cl-white--darker;
}

.background--full-height {
  height: calc(100vh - 8rem);
}
