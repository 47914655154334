@import 'styles/grid';
@import 'styles/colors';
@import 'styles/variables';

.container {
  display: inline-block;
  // min-width: min-content;
  max-width: 100%;
}

.wrapper {
  display: inline-flex;
  border: .1rem solid $cl-grey--light;
  border-radius: .6rem;
  overflow: hidden;
  line-height: 1.5;
  color: $cl-black--light;
  font-size: 1.6rem;
}

.label {
  flex-shrink: 0;
  padding: 1.2rem .8rem;
  white-space: nowrap;

  @include min(s) {
    padding: 1.2rem 1.6rem;
  }
}

.label--prepend {
  border-right: .1rem solid $cl-grey--light;
}

.label--append {
  border-left: .1rem solid $cl-grey--light;
}

.select {
  appearance: none;
  width: 100%;
  border: 0;
  padding: 1.2rem 2.1rem 1.2rem .8rem;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.5;
  cursor: pointer;
  background: url('/images/icons/chevron-down.svg') calc(100% - .8rem) 50% no-repeat, $cl-white;

  @include min(s) {
    padding: 1.2rem 3.2rem 1.2rem 1.2rem;
    background-position: calc(100% - 1.6rem) 50%;
  }
}

.select--no-value {
  color: $cl-grey;
}

.option {
  padding: 1.2rem .8rem;
  color: $cl-black--light;
}
