@import '/styles/colors';
@import '/styles/variables';
@import '/styles/mixins';
.modal-dialog {
  z-index: 20; // TODO: Refactor all z-indexes
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &__overlay {
    width: 100%;
    height: 100%;
    background-color: rgba($cl-black, .2);
    position: fixed;
  }

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 90vh;
    max-width: 90vw;
    background-color: white;
    overflow: hidden;
    border-radius: 0.6rem;
    width: initial;

    &__top {
      background-color: white;
      position: relative;
      width: inherit;
      max-width: 90vw;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1.6rem;
    }

    &__top--shadow {
      box-shadow: 0 8px 6px -2px rgba(0,0,0,.04);
    }

    &__close {
      cursor: pointer;
    }

    &__title {
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 1.33;
      color: $cl-secondary--darker;
      align-self: center;
      margin: 0 auto; // center by default
    }

    &__title--left {
      flex: 1;
    }

    &__body {
      overflow: hidden;
    }

    &__body--scrollable {
      overflow: auto;
    }
  }
}

@media screen {
  .print-only {
    display: none;
  }
}

@media print {
  .print-only {
    display: block;
  }
}
