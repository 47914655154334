@import 'styles/colors';
@import 'styles/variables';
@import 'styles/grid';

.avatar {
  position: relative;
  width: 3.2rem;
  height: 3.2rem;
  margin-right: .8rem;
  border: .1rem solid rgba($cl-black, .1);
  background: {
    repeat: no-repeat;
    position: center;
    size: cover;
  }
  border-radius: 50%;
}

.account {
  position: relative;

  &:nth-child(2) {
    margin-left: auto;
  }
}

.badge {
  position: absolute;
  top: -1rem;
  left: -1rem;
  width: 2rem;
  height: 2rem;
  color: $cl-white;
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: center;
  background-color: $cl-secondary--darker;
  border-radius: 50%;
}

.accountVisible {

  .accountList {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  > .accountButton > .accountButtonIcon {
    transform: rotate(-180deg);
  }
}

.accountButton {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 1.5rem;
  color: $cl-secondary--darker !important;
  font-size: 1.6rem;
  line-height: 1;
  font-weight: $font-weight--bold;
  text-decoration: none;
  border: none;
  white-space: nowrap;
  transition: background-color 200ms;

  img {
    margin-left: .8rem;
    margin-right: 0;
  }
}

.accountButtonIcon {
  transition: transform 250ms;
}

.accountList {
  visibility: hidden;
  opacity: 0;
  z-index: 10;
  position: absolute;
  top: 100%;
  right: .8rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 20rem;
  background-color: $cl-white;
  border: .1rem solid $cl-grey--lighter;
  box-shadow: .0rem .1rem .4rem rgba($cl-shadow, .08), .0rem .6rem 1.2rem rgba($cl-shadow, .1);
  border-radius: .6rem;
  transform: translate3d(0, .8rem, 0);
  transition: {
    property: opacity, visibility, transform;
    duration: 250ms;
  }

  >.accountListButton:first-child {
    padding-top: 2rem;
  }
}

.accountListDivider {
  width: calc(100% - 3.2rem);
  margin: .4rem 1.6rem;
  height: .1rem;
  background-color: $cl-grey--lightest;
}

.accountListButton {
  cursor: pointer;
  position: relative;
  width: 100%;
  color: $cl-black--light;
  text-align: left;
  padding: 1.2rem 1.6rem;
}

.accountListButtonBadge::after {
  content: "";
  display: inline-block;
  width: .4rem;
  height: .4rem;
  margin-left: .4rem;
  background-color: $cl-secondary--darker;
  border-radius: 50%;
  transform: translate3d(0, -.8rem, 0);
}

.wrapper {
  overflow: hidden;
  max-height: 0;
  transition: max-height 200ms;
}

.hostSettingsSubmenu {
  > .accountButton {
    width: 100%;
    justify-content: space-between;
    padding-bottom: 1.2rem;
    > span {
      color: $cl-grey;
    }
  }
}

.hostSettingsVisible {
  .wrapper {
    max-height: 20rem;
  }
  .accountButtonIcon {
    transform: rotate(180deg);
  }

  .accountButton:last-child {
    padding-bottom: 1.4rem;
  }
}

.accountListButton.icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionButton {
  justify-content: center;
  margin: 0 1.2rem 1rem 1.2rem;
}
