@import 'styles/variables';
@import 'styles/grid';
@import 'styles/colors';
@import 'styles/mixins';

.default {
  position: relative;
  width: 100%;
}

.primary {
  @extend .default;
  display: flex;
  flex-direction: column;
  min-height: 68rem;
  padding-bottom: 3.2rem;

  @include min(s) {
    height: 82.8rem;
  }

  .background {
    position: absolute;
    top: 1.6rem;
    bottom: 0;
    left: 50%;
    width: 50%;
    max-width: 72rem;
    height: calc(100% - 14.4rem);
    padding-right: 1.6rem;

    @include min(s) {
      top: 3.2rem;
    }

    @include max(s){
      >.desktop {
        display: none;
      }
    }
    img {
      object-fit: contain;
      object-position: top;
    }
  }

  .description {
    margin-bottom: 3.2rem;
  }

  .container:last-child {
    padding-top: 0;
  }

  + section {
    padding-top: 0;
  }
}

.secondary {
  @extend .default;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include min(m) {
    height: 73.6rem;
  }

  .container {

    @include max(m) {
      padding: 0 1.6rem;
    }
  }

  .container:last-child {
    padding-bottom: 10.4rem;

    @include max(m) {
      padding-top: 10.4rem;
    }
  }

  .heading {
    margin-bottom: .8rem;
    color: $cl-white;
  }

  .description {
    margin-bottom: 3.2rem;
    color: $cl-white;
    font-weight: $font-weight--book;
  }
}

.tertiary {
  position: relative;
  width: 100%;

  .container {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }

  > * {
    z-index: 1;
    position: relative;
  }

  .heading {
    margin-bottom: 1.6rem;
  }

  .heading-container {
    align-self: center;
  }

  .description-container {
    display: none;
  }

  .description {
    @include font-size(2.4rem, 3.2rem);
    margin-bottom: 3.2rem;
    line-height: 1.5;

    @include min(m) {
      margin-bottom: 0;
    }
  }

  .media {
    @extend .col-m6;
    display: flex;
    justify-content: center;

    .image {
      width: 100%;
    }

    img {
      width: 100%;
      border-radius: .6rem;
    }
  }
}

.background {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
  }
}

.container {
  @extend .container;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-top: 2.4rem;
  text-align: left;

  @include min(s) {
    padding-top: 9.6rem;
  }
}

.heading,
.description {
  z-index: 0;
  position: relative;
}

.heading-container {
  @extend .col-m6;
}

.heading {
  margin-bottom: 1.6rem;
  color: $cl-secondary--darker;
  @include max(s) {
    font-size: 4rem;
  }
}

.description-container {
  @extend .col-m6;
  max-width: 100%;
}

.description-container--large {
  @extend .col-m8;
  max-width: 100%;

  button + button {
    margin-left: 1.6rem;
  }
}

.description {
  color: $cl-black--lighter;
  font-weight: $font-weight--book;
  @include max(s) {
    font-size: 2rem;
  }
}

.logos {
  @extend .container;
  margin-top: auto;
  padding-bottom: 4rem;

}

.logosList {
  @include min(s) {
    .mobile {
      display: none;
    }
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 1.6rem;
  }
  @include max(m) {
    .desktop {
      display: none;
    }
  }

  @include min(xs) {
    flex-direction: row;
    align-items: flex-start;
  }

  img {
    // padding: 0 40px;
    @include min(xs) {
      margin-right: 2.4rem;
    }
  }
}

.search {
  z-index: 10;
  position: relative;
  display: flex;
  max-width: 100%;
}

.submit {
  align-items: center;
  height: 4.8rem;
  border-radius: 0 .6rem .6rem 0;
}

.mobileBackground {
  margin-left: 1.6rem;
  margin-top: 3.2rem;
  margin-bottom: 5.2rem;
  @include min(s) {
    display: none;
  }
}
