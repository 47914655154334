@import '../../../styles/variables';
@import '../../../styles/grid';
@import '../../../styles/mixins';

.container {
  @extend .container;
}

.grid {
  @extend .grid;

  @include max(m) {
    row-gap: 3.2rem;
  }

  &--primary {

  }

  &--secondary {
    column-gap: 0;
  }

  &--quaternary {
    column-gap: 1rem;
  }

  &--quinary {
    @include min(m) {
      transform: translateX(-3.2rem);
    }
  }
}

.primary {

  .item {
    @extend .col-m4;
    text-align: center;
  }

  .header {
    flex-direction: column;
  }

  .icon {
    display: flex;
    justify-content: center;
  }
}

.secondary {
  .item {
    @extend .col-m4;
    margin: 0;
    text-align: center;

    @include min(m) {
      margin: 0 3.2rem;
      text-align: left;
    }
  }

  .circle {
    justify-content: center;
    width: 6.4rem;
    height: 6.4rem;
    color: $cl-primary;
    font-size: 3.2rem;
    border-radius: 50%;
    box-shadow: 0 0 0 .3rem $cl-primary inset;

    @include min(m) {
      margin-right: auto;
    }
  }

  .heading {
    color: $cl-secondary--darker;
    font-size: 2rem;
    line-height: 1.4;
  }
}

.tertiary {

  + .tertiary {
    margin-top: 7.2rem;

    @include max(m) {
      margin-top: 3.2rem;
    }
  }

  .heading {

    span {
      color: $cl-primary;
    }
  }

  .item {
    @extend .col-m6;

    p {
      padding-left: 3.6rem;
    }
  }

  .header {

    .icon {
      margin: .4rem 1.2rem 0 0;

      @include max(m) {
        margin: .2rem 1.2rem 0 0;
      }
    }
  }
}

.quaternary {

  .header {
    justify-content: center;

    @include min(m) {
      justify-content: flex-start;
    }
  }

  .heading {
    width: auto;

    @include min(m) {
      width: 100%;
    }

    span {
      color: $cl-primary;
    }
  }

  .item {
    @extend .col-m4;
    align-items: flex-start;
    margin: 0;
  }

  .header {

    .icon {
      margin: .4rem 1.2rem 0 0;

      @include max(m) {
        margin: .2rem 1.2rem 0 0;
      }
    }
  }
}

.quinary {
  .item {
    @extend .col-m4;
    margin: 0;
    text-align: center;

    @include min(m) {
      margin: 0 3.2rem;
      text-align: left;
    }

    @include max(m) {
      br {
        display: none;
      }
    }
  }

  .circle {
    justify-content: center;
    width: 6.4rem;
    height: 6.4rem;
    color: $cl-primary;
    font-size: 3.2rem;
    border-radius: 50%;
    box-shadow: 0 0 0 .3rem $cl-primary inset;

    @include min(m) {
      margin-right: auto;
    }
  }

  .heading {
    color: $cl-secondary--darker;
    font-size: 2rem;
    line-height: 1.4;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;

  @include min(m) {
    flex-wrap: nowrap;
  }
}

.header {
  display: flex;
  width: 100%;
  margin-bottom: .8rem;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 100%;
}

.heading {
  width: 100%;
}

.icon,
.counter {
  margin-bottom: 1.6rem;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: $cl-secondary--darker;
  font-size: 6.4rem;
  font-weight: $font-weight--black;

  @include min(m) {
    justify-content: flex-start;
  }

  span {
    color: $cl-primary;
    font-size: 4.8rem;
    padding-left: .8rem;
  }
}

.description {
  width: 100%;
  font-size: 1.8rem;
  line-height: 1.778;
}
