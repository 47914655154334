@import 'styles/variables';
@import 'styles/grid';
@import 'styles/colors';
@import 'styles/mixins';

.default {
  position: relative;
  width: 100%;
}

.primary {
  @extend .default;
  display: flex;
  flex-direction: column;
  height: 68rem;
  padding-bottom: 3.2rem;

  @include min(s) {
    height: 92.8rem;
  }

  .background {
    position: absolute;
    top: 11.2rem;
    bottom: 0;
    left: 50%;
    width: 50%;
    max-width: 72rem;
    height: calc(100% - 14.4rem);

    img {
      object-fit: contain;
      object-position: top;
    }
  }

  .description {
    margin-bottom: 4rem;
  }

  .container:last-child {
    padding-top: 0;
  }

  + section {
    padding-top: 0;
  }
}

.secondary {
  @extend .default;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  + * {
    @include max(s) {
      margin-top: 40rem;
    }
  }

  .container {

    @include max(m) {
      padding: 0 1.6rem;
    }
  }

  .container:last-child {
    padding-top: 3.2rem;

    @include min(s) {
      padding: 9.6rem 0 11.2rem;
    }
  }

  .heading-container {
    margin-bottom: 0;

    @include min(s) {
      margin-top: 0;
    }
  }

  .heading {
    margin-bottom: .8rem;
    color: $cl-white;
  }

  .description {
    margin-bottom: 3.2rem;
    color: $cl-white;
    font-weight: $font-weight--book;
  }
}

.tertiary {
  position: relative;
  width: 100%;

  .container {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }

  .grid {
    @include max(xxl) {
      overflow: hidden;
    }

    @include min(xxl) {
      min-height: 54.4rem;
    }
  }

  > * {
    z-index: 1;
    position: relative;
  }

  .heading {
    margin-bottom: 1.6rem;
  }

  .heading-container {
    align-self: center;
  }

  .description-container {
    display: none;
  }

  .description {
    @include font-size(2.4rem, 3.2rem);
    margin-bottom: 3.2rem;
    line-height: 1.5;

    @include min(m) {
      margin-bottom: 0;
    }
  }

  .media {
    @extend .col-m6;
    display: flex;
    justify-content: center;

    .image {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      border-radius: .6rem;

      @include min(xxl) {
        height: 100%;
        width: auto;
        max-width: none;
      }
    }
  }
}

.background {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.container {
  @extend .container;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  text-align: left;
  padding-top: 9.6rem;
}

.heading,
.description {
  z-index: 0;
  position: relative;
}

.heading-container {
  @extend .col-m6;
  align-self: center;
}

.heading {
  margin-bottom: 1.6rem;
  color: $cl-secondary--darker;
}

.description-container {
  @extend .col-m6;
  max-width: 100%;
}

.description {
  color: $cl-black--lighter;
  font-weight: $font-weight--book;
}

.logos {
  @extend .container;
  margin-top: auto;
  padding-bottom: 4rem;
}

.logosList {
  margin-top: 1.6rem;

  img {
    margin-right: 2.4rem;
  }
}
