@import '../../../styles/variables';
@import '../../../styles/grid';

.grid {
  @extend .grid;
}

.step {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  height: 8rem;
  margin-bottom: 1.6rem;
  padding: 2.4rem 3.2rem;
  background-color: $cl-white--darker;
  border-radius: .6rem;
  color: $cl-secondary--dark;
  text-decoration: none;
  transition: {
    property: height, color, background-color;
    duration: 200ms;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    flex-grow: 1;
    background-color: $cl-secondary--lighter;

    .heading {
      color: $cl-secondary--dark;
    }

    .count {
      background-color: $cl-secondary--dark;
    }
  }
}

.media {
  @extend .col-m6;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  margin-bottom: 3.2rem;
  background-color: $cl-secondary--lighter;
  border-radius: .6rem;

  @include min(m) {
    margin-bottom: 0;
  }
}

.image {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: opacity 200ms;

  &.active {
    opacity: 1;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.steps {
  @extend .col-m6;
  display: flex;
  flex-direction: column;
  // height: 46.4rem;
}

.steps.top {
  grid-row-start: 1;
  margin-bottom: .8rem;
  .heading {
    justify-content: left;
  }
  .step {
    height: 100%;
  }
  .content {
    text-align: left;
    font-size: 1.6rem;
  }
}

.content {
  @extend .col-m6;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  color: $cl-black--light;
  font-size: 1.8rem;
  line-height: 1.556;
  text-align: center;
  transition: height 250ms;

  @include min(m) {
    text-align: left;
  }

  p {
    margin: 1.2rem 0 0;
  }
}

.heading {
  display: flex;
  color: $cl-grey--dark;
  transition: color 200ms;

  @include max(m) {
    align-items: center;
    justify-content: center;
  }
}

.count {
  display: block;
  min-width: 2.4rem;
  height: 2.4rem;
  margin-right: 1.2rem;
  color: $cl-white;
  font-size: 1.8rem;
  line-height: 2.4rem;
  text-align: center;
  background-color: $cl-grey--dark;
  border-radius: 50%;
  transition: background-color 200ms;

  @include min(m) {
    margin-top: .15em;
  }
}
