@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/grid';

.modalContainer {
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 2.4rem;
  background-color: rgba($cl-black, .2);
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  transition: {
    property: opacity, visibility;
    duration: 300ms;
  };
}

.modalContainerVisible {
  opacity: 1;
  visibility: visible;
}

.modal {
  position: relative;
  width: 41.6rem;
  margin: auto;
  padding: 4.8rem 3.2rem 3.2rem;
  background: $cl-white;
  box-shadow: 0 .1rem .2rem rgba($cl-black--light, .05),
              0 .6rem 1.2rem .2rem rgba($cl-black--light, .1);
  border-radius: .6rem;
  transform: scale(.95);
  transition: {
    property: transform;
    duration: 350ms;
  };

  .modalContainerVisible & {
    transform: scale(1);
  }

  @include max(m) {
    padding: 1.6rem;
  }
}

.modalClose {
  appearance: none;
  z-index: 30;
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 2.4rem;
  height: 2.4rem;
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    opacity: .5;
  }
}
