@import 'styles/colors';
@import 'styles/variables';
@import 'styles/grid';

.label {
  display: flex;
  flex-direction: column;
}

.input {
  appearance: none;
  box-sizing: border-box;
  max-height: 4.8rem;
  padding: 1.2rem 1.6rem;
  font-size: 1.6rem;
  line-height: 1.5;
  border-radius: .6rem;
  border: .1rem solid $cl-grey--lighter;
  background: $cl-white;
}

.select {
  @extend .input;
  padding-right: 4rem;
}

.row {
  display: flex;
  margin: 0 -.8rem 1.6rem;
}
