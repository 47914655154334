@import '../../../styles/variables';
@import '../../../styles/grid';
@import '../../../styles/colors';
@import '../../../styles/mixins';


.alignLeft {

  .title,
  .subtitle {
    text-align: left;
  }
}

.primary {
  margin: 0 auto 6.4rem;

  .title,
  .subtitle {
    @extend .col-m8;
    @extend .push-m2;
  }

  &.alignLeft {

    .title,
    .subtitle {
      @extend .col-m8;
      grid-column-start: 1;
    }
  }
}

.secondary {
  margin: 0 0 6.4rem;

  @include max(m) {
    margin: 0;
  }

  .title,
  .subtitle {
    @extend .col-m12;
    grid-column-start: 1;
    margin-left: 0;
    margin-right: 0;
    text-align: left;
  }

  .subtitle {
    @include max(m) {
      margin-bottom: 4.8rem;
    }
  }
}

.tertiary {
  margin-bottom: 2.4rem;

  .title,
  .subtitle {
    margin-bottom: 0;
  }
}

.inverted {

  .title,
  .subtitle {
    color: $cl-white;
  }
}

.title {
  margin-bottom: 1.6rem;
  color: $cl-secondary--darker;
  text-align: center;
}

.subtitle {
  font-size: 2.4rem;
  line-height: 1.667;
  text-align: center;
}

.columns {
  @include font-size(1.8rem, 2.4rem);
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 1.667;
  text-align: left;

  @include min(m) {
    flex-direction: row;
  }
}

.column {
  @extend .col-m6;
  flex-basis: 100%;
  margin-right: 3.2rem;
  margin-bottom: 3.2rem;

  @include min(m) {
    margin-bottom: 6.4rem;
  }

  &:last-child {
    margin-right: 0;
  }

  @include min(m) {
    margin-bottom: 0;
  }
}
