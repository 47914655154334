@import '../../../styles/variables';
@import '../../../styles/grid';
@import '../../../styles/colors';
@import '../../../styles/mixins';

.default {
  width: 100%;
  padding: 6.4rem 0;

  @include min(m) {
    padding: 12rem 0;
  }
}

.primary {
  color: $cl-black--lighter;
  background-color: $cl-white;
}

.secondary {
  color: $cl-black--lighter;
  background-color: $cl-primary--lightest;
}

.tertiary {
  color: $cl-white;
  background-color: $cl-secondary--darker;

  .section__title {
    color: $cl-white;
  }
}

.quaternary {
  background-color: $cl-secondary--lighter;
}

.narrow {
  padding: 5.6rem 0;
}

.border {
  border-bottom: .1rem solid #E6E7E8;
}

.narrow {
  padding: 5.6rem 0;
}
