@import '../../../styles/variables';
@import '../../../styles/grid';

.heading {
  @extend .col-m8;
  @extend .push-m2;
  margin-bottom: 1.6rem;
  color: $cl-secondary--darker;
  text-align: center;
  font-weight: $font-weight--bold;
}

.description {
  @extend .col-m8;
  @extend .push-m2;
  margin: 0 0 4.8rem;
  font-size: 2.4rem;
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: center;

  .button {
    margin: 0 .8rem;
  }
}
