@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/grid';

.primary {
  width: 100%;
  height: auto;
  color: $cl-grey;

  @include max(m) {
    background-color: white;
    z-index: 4;
 }
  // @include min(m) {
  //   height: 10.4rem;
  // }
}

.container {
  @extend .container;
  height: 100%;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: .1rem;
    box-shadow: 0 -.1rem 0 0 $cl-grey--lightest;
  }
}

.prefooter {
  @extend .container;

  &::before {
    display: none;
  }

  .list_items {
    margin: 0;
  }

  .item {

    &:first-child {
      padding-left: 0;
    }
    @include max(m) {
      padding: 0;
    }
  }

  .anchor {
    color: $cl-secondary--darker;
  }
}

.prefooter-bottom {
  @extend .container;
  color: $cl-grey--dark;

  .navigationSecondary {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 3.2rem;
    width: 100%;

    @include max(s) {
      grid-template-columns: auto;
      grid-template-rows: repeat(4, 1fr);
      grid-row-gap: 1.6rem;
    }
  }

  .prefooter-column {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    line-height: 1.75;

    + li {
      margin-top: .8rem;
    }

    @include max(s) {
      text-align: center;
    }
  }

  .anchor {
    text-decoration: underline;
    color: $cl-grey--dark;
  }
}

.group {
  @extend .group;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 4rem 0;

  @include max(m) {
    flex-direction: column;
    padding: 2.4rem 0;
  }
}

.list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.list_items {
  display: flex;
  justify-content: center;
  margin: 0 0 0 1.6rem;
  padding: 0;
  list-style: none;

  @include max(m) {
    flex-direction: column;
    text-align: center;

    .item {
      margin-bottom: 1.6rem;
    }
  }
}

.column {
  @extend .col-m6;
  display: flex;
  flex-direction: column;

  @include min(m) {
    flex-direction: row;
  }

  span {
    width: 100%;
    margin-bottom: 1.6rem;
    text-align: center;

    @include min(m) {
      width: auto;
      margin-bottom: 0;
    }
  }
}

.anchor {
  color: $cl-grey;
  text-decoration: none;
  display: block;
}

.item {
  padding: 0 1.6rem;
}

.social {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1.6rem;

  @include min(m) {
    width: auto;
    margin: 0 0 0 auto;
  }

  .item {
    padding: 0 1.2rem;

    &:last-child {
      padding-right: 0;
    }
  }
}

.hideOnDesktop {
  @include min(s) {
    display: none;
  }
}
