@import 'styles/colors';
@import 'styles/variables';
@import 'styles/grid';

.grid {
  @extend .grid;
}

.content {
  @extend .col-m6;
  margin-bottom: 3.2rem;
}

.heading {
  margin-bottom: 3.2rem;
  color: $cl-secondary--darker;
}

.subtitle {
  color: $cl-black--light;
  font-size: 2.4rem;
  line-height: 1.667;
}

.list {
  @extend .col-m5;
  @extend .push-m7;
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 3.2rem;
  color: $cl-secondary--darker;
  font-size: 1.8rem;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: "";
    flex-shrink: 0;
    display: block;
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1.6rem;
    background: url('/images/check-shield.svg') no-repeat;
  }
}
