@import 'styles/colors';
@import 'styles/variables';
@import 'styles/grid';

.default {
  z-index: 20;
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  height: 6.4rem;
  border-bottom: .1rem solid $cl-grey--border;
  background-color: $cl-white;
  transition: {
    property: background-color;
    duration: 200ms;
  };

  @include min(s) {
    height: 8rem;
  }

  + * {
    @include max(s) {
      padding-top: 6.4rem;
    }

    @include range(s, xxl) {
      padding-top: 8rem;
    }

    @include min(xxl) {
      padding-top: 8rem;
    }
  }

  .center {
    transition: opacity 300ms, transform 300ms, visibility 300ms;
      // margin-left: 36.3rem;
  }

  :global .overlayWrapper .DayPicker {
    top: 5.6rem;
    left: unset;
    right: 0;
  }

  @media print {
    display: none;
  }
}

.default--has-search {
  + * {
    transition: padding-top 200ms;
  }

  &:not(.location-search-hidden) {
    + * {
      @include max(s) {
        padding-top: 11.2rem;
      }

      @include range(s, l) {
        padding-top: 12.8rem;
      }

      @include min(l) {
        padding-top: 8rem;
      }
    }
  }
}

.secondary {
  a {
    @include min(m) {
      color: $cl-white;
    }
  }
}

.isOpaque-primary {
  background-color: $cl-white;
  box-shadow: 0 .1rem .2rem rgba($cl-black--light, .05),
  0 .6rem 1.2rem .2rem rgba($cl-black--light, .05);
}

.isOpaque-secondary {

  @include min(m) {
    background-color: $cl-secondary--darker;
    box-shadow: 0 .1rem .2rem rgba($cl-black--light, .05),
    0 .6rem 1.2rem .2rem rgba($cl-black--light, .05);
  }
}

.logo {
  display: flex;
  align-items: center;
  width: 12.6rem;
  height: 2rem;
  margin: 0 3.2rem 0 1.6rem;

  @include min(m) {
    margin-left: 0;
  }

  @include min(xxl) {
    width: 15.2rem;
    height: 2.4rem;
  }

  @include max(xxl) {
    margin-right: 1.6rem;
  }

  img {
    display: block;
    max-width: 100%;
  }
}

.toggle {
  cursor: pointer;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  margin-right: -.8rem;
  padding: 0;
  background-color: $cl-white;
  border: none;

  @include min(s) {
    display: none;
  }
}

.toggleIcon {
  z-index: 1;
  position: relative;
  width: 1.6rem;
  height: .2rem;
  background-color: $cl-secondary--darker;
  border-radius: 1rem;
  transition: background-color 300ms;

  .toggleActive & {
    background-color: transparent;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 1.6rem;
    height: .2rem;
    background-color: $cl-secondary--darker;
    border-radius: 1rem;
    transition: top 300ms, transform 300ms;
  }

  &::before {
    top: -.6rem;

    .toggleActive & {
      top: -1.4rem;
      transform: rotate(45deg) translate3d(1rem, 1rem, 0);
      transform-origin: center;
    }
  }

  &::after {
    top: .6rem;

    .toggleActive & {
      top: 0;
      transform: rotate(-45deg);
      transform-origin: center;
    }
  }
}

.container {
  @extend .container;
  display: flex;
  align-items: center;
  // justify-content: center;
  max-width: 144rem;
  padding: 0;

  @include min(m) {
    padding: 0 1.6rem;
  }

  .container {
    @include min(l) {
      padding: 0;
    }
  }
}

.left {
  display: flex;
  align-items: center;
}

.center {
  flex-grow: 1;
  top: 100%;
  left: 0;
  right: 0;
  padding: 0;
  pointer-events: none;

  .container {
    height: 100%;

    > * {
      pointer-events: auto;
    }

    @include min(l) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @include max(m) {
    position: absolute;
  }

  @include min(m) {
    padding: .8rem 0;
  }

  @include min(l) {
    position: absolute;
    z-index: $zIndex--middle;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.right {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-left: auto;
  padding-left: 3.2rem;

  @include max(xxl) {
    padding-left: 2.4rem;
  }

  @include max(m) {
    margin-right: 1.6rem;
    .left-buttons {
      display: none;
    }
    .left-mobileButtons {
      display: block;
    }
  }
  @include min(m) {
    .left-mobileButtons {
      display: none;
    }
  }
  @include min(s) {
    button {
      + button {
        margin-left: 1.6rem;
      }
    }
  }

}
.right.listing-detail {
  justify-content: flex-end;
  padding-left: 0;
  :first-child {
    margin-right: auto;
  }

  .price {
    display: flex;
    align-items: center;
    padding-right: 3.2rem;
    color: $cl-grey--darkest;
    small {
      margin-right: .4rem;
      color: $cl-grey--darkest;
    }

    @include max(m) {
      display: none;

      .location {
        font-size: 1.4rem;
      }
    }
  }

  @include max(m) {
    margin-right: 0;
    .price + button {
      display: none;
    }
    .location {
      display: none;
    }
  }
}

.overlay {
  visibility: hidden;
  opacity: 0;
  z-index: 20;
  position: fixed;
  top: 7.2rem;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($cl-black--lighter, .4);
  transition: {
    property: visibility, opacity;
    duration: 300ms;
  }
}

.overlayVisible {
  visibility: visible;
  opacity: 1;

  @include min(m) {
    visibility: hidden;
    opacity: 0;
  }
}

.button {
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  padding: 1.2rem 1.6rem;
  color: $cl-white;
  font-size: 1.4rem;
  font-weight: $font-weight--bold;
  line-height: 1;
  text-decoration: none;
  border: none;
  margin-left: 1.6rem;
  background-color: $cl-secondary--darker;
  box-shadow: 0 0 0 .1rem rgba($cl-secondary--dark, .1) inset;
  border-radius: 1.2rem;

  @include min(m) {
    font-size: 1.6rem;
    padding: 1.6rem;
  }

  &:hover,
  &:focus {
    background-color: $cl-secondary--dark;
  }

  &:active {
    background-color: $cl-secondary--darker;
  }

  &.primary {
    background-color: $cl-primary;

    &:hover,
    &:focus {
      background-color: $cl-primary--light;
    }

    &:active {
      background-color: $cl-primary;
    }
  }
}

.buttonInverted {
  color: $cl-secondary--darker;
  background-color: $cl-white;

  &:hover,
  &:focus {
    background-color: $cl-grey--lightest;
  }

  &:active {
    background-color: $cl-white;
  }
}

.desktop-anchors {
  display: flex;

  @include max(s) {
    display: none;
  }

  .text-anchor {
    color: $cl-black--light;
    &.secondary {
      color: $cl-secondary;
    }
  }
}

.text-anchor {
  display: flex;
  align-items: center;
  margin-right: 3.2rem;
  color: $cl-secondary;
  font-size: 1.6rem;
  font-weight: $font-weight--medium;
  text-decoration: none;
  white-space: nowrap;

  @include max(xxl) {
    margin-right: 1.6rem;
  }

  &.secondary {
    display: none;
    margin-right: 0;
    color: $cl-secondary;

    @include min(m) {
      display: flex;
    }
  }
}

.mobileNavigation {
  position: relative;
}

.mobileDropdown {
  visibility: hidden;
  opacity: 0;
  z-index: 10;
  position: absolute;
  top: calc(100% - .8rem);
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 20rem;
  background-color: $cl-white;
  border: .1rem solid $cl-grey--lighter;
  box-shadow: .0rem .1rem .4rem rgba($cl-shadow, .08), .0rem .6rem 1.2rem rgba($cl-shadow, .1);
  border-radius: .6rem;
  transform: translate3d(0, 1rem, 0);
  transition: {
    property: opacity, visibility, transform;
    duration: 250ms;
  }

  @include min(s) {
    display: none;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  .mobileDropdownButton:first-child {
    padding-top: 2rem;
  }

  .mobileDropdownButton:last-child {
    padding-bottom: 2rem;
  }
  :first-child {
    justify-content: center;
  }
}

.mobileDropdownDivider {
  width: calc(100% - 3.2rem);
  margin: .8rem 1.6rem;
  height: .1rem;
  background-color: $cl-grey--lighter;
}

.mobileDropdownButton {
  cursor: pointer;
  position: relative;
  width: 100%;
  color: $cl-black--light;
  text-align: left;
  padding: 1.2rem 1.6rem;
}

.search {
  z-index: 10;
  position: relative;
  display: flex;
  flex-grow: 1;
  max-width: 100%;

  > div,
  > label {
    flex-grow: 1;
  }

  @include max(m) {
    border-top: .1rem solid $cl-grey--border;
    border-bottom: .1rem solid $cl-grey--border;
  }
}

.submit {
  align-items: center;
  height: 4.8rem;
  border-radius: 0;

  @include min(m) {
    border-radius: 0 .6rem .6rem 0;
  }
}

.rounded {
  border-radius: 0;

  @include min(m) {
    border-radius: 0 .6rem .6rem 0;
  }

  @include max(s) {
    width: 4.8rem;

    span {
      display: none;
    }
  }
}

.hidden {
  @include max(s) {
    display: none;
  }
}
