@import '../../../styles/variables';
@import '../../../styles/colors';
@import '../../../styles/grid';

.primary {
  width: 100%;
  padding: 6.4rem 0;
}

.container {
  @extend .container;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include min(m) {
    justify-content: space-between;
  }
}

.heading {
  width: 100%;
  margin-bottom: 2.4rem;
  color: $cl-black--lighter;
  font-weight: $font-weight--bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .08rem;
}
