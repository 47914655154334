@import 'styles/colors';
@import 'styles/variables';
@import 'styles/grid';

.default {
  z-index: 20;
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  height: 6.4rem;
  background-color: $cl-white;
  box-shadow: 0 -.1rem 0 0 $cl-grey--lightest inset;
  transition: {
    property: background-color;
    duration: 200ms;
  };

  @include min(s) {
    height: 8rem;
  }
}

.secondary {

  a {

    @include min(m) {
      color: $cl-white;
    }
  }
}

// .isOpaque-primary {
//   background-color: $cl-white;
//   box-shadow: 0 .1rem .2rem rgba($cl-black--light, .05),
//   0 .6rem 1.2rem .2rem rgba($cl-black--light, .05);
// }

.isOpaque-secondary {

  @include min(m) {
    background-color: $cl-secondary--darker;
    box-shadow: 0 .1rem .2rem rgba($cl-black--light, .05),
    0 .6rem 1.2rem .2rem rgba($cl-black--light, .05);
  }
}

.logo {
  display: flex;
  align-items: center;
  width: 12.6rem;
  height: 2rem;
  margin: 0 0 0 1.6rem;

  @include min(m) {
    width: 15.2rem;
    height: 2.4rem;
  }

  img {
    display: block;
    max-width: 100%;
  }
}

.toggle {
  cursor: pointer;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  padding: 0;
  background-color: $cl-white;
  border: none;

  @include min(s) {
    display: none;
  }
}

.toggleIcon {
  z-index: 1;
  position: relative;
  width: 1.6rem;
  height: .2rem;
  background-color: $cl-secondary--darker;
  border-radius: 1rem;
  transition: background-color 300ms;

  .toggleActive & {
    background-color: transparent;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 1.6rem;
    height: .2rem;
    background-color: $cl-secondary--darker;
    border-radius: 1rem;
    transition: top 300ms, transform 300ms;
  }

  &::before {
    top: -.6rem;

    .toggleActive & {
      top: -1.4rem;
      transform: rotate(45deg) translate3d(1rem, 1rem, 0);
      transform-origin: center;
    }
  }

  &::after {
    top: .6rem;

    .toggleActive & {
      top: 0;
      transform: rotate(-45deg);
      transform-origin: center;
    }
  }
}

.container {
  @extend .container;
  display: flex;
  align-items: center;
  max-width: 144rem;
  padding: 0;

  @include min(m) {
    padding: 0 1.6rem;
  }
}

.left {
  display: flex;
  align-items: center;
}

.center {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  padding: 0;
  background-color: $cl-white;
  box-shadow: 0 .1rem 0 0 $cl-grey--lightest;

  @include min(m) {
    padding: .8rem 0;
  }

  @include min(xxl) {
    position: relative;
    top: 0;
    left: 0;
    width: auto;
    box-shadow: none;
  }
}

.right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.overlay {
  visibility: hidden;
  opacity: 0;
  z-index: 20;
  position: fixed;
  top: 7.2rem;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($cl-black--lighter, .4);
  transition: {
    property: visibility, opacity;
    duration: 300ms;
  }
}

.overlayVisible {
  visibility: visible;
  opacity: 1;

  @include min(m) {
    visibility: hidden;
    opacity: 0;
  }
}

.button {
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  padding: 1.2rem 1.6rem;
  color: $cl-white;
  font-size: 1.4rem;
  font-weight: $font-weight--bold;
  line-height: 1;
  text-decoration: none;
  border: none;
  margin-left: 1.6rem;
  background-color: $cl-secondary--darker;
  box-shadow: 0 0 0 .1rem rgba($cl-secondary--dark, .1) inset;
  border-radius: 1.2rem;

  @include min(m) {
    font-size: 1.6rem;
    padding: 1.6rem;
  }

  &:hover,
  &:focus {
    background-color: $cl-secondary--dark;
  }

  &:active {
    background-color: $cl-secondary--darker;
  }

  &.primary {
    background-color: $cl-primary;

    &:hover,
    &:focus {
      background-color: $cl-primary--light;
    }

    &:active {
      background-color: $cl-primary;
    }
  }
}

.buttonInverted {
  color: $cl-secondary--darker;
  background-color: $cl-white;

  &:hover,
  &:focus {
    background-color: $cl-grey--lightest;
  }

  &:active {
    background-color: $cl-white;
  }
}

.desktop-anchors {
  display: flex;

  @include max(s) {
    display: none;
  }

  .text-anchor {
    color: $cl-black--light;
  }
}

.text-anchor {
  display: flex;
  align-items: center;
  margin-right: 3.2rem;
  color: $cl-secondary;
  font-size: 1.6rem;
  font-weight: $font-weight--medium;
  text-decoration: none;
  white-space: nowrap;

  &.secondary {
    display: none;
    margin-right: 0;

    @include min(m) {
      display: flex;
    }
  }
}

.mobileNavigation {
  position: relative;
}

.mobileDropdown {
  visibility: hidden;
  opacity: 0;
  z-index: 10;
  position: absolute;
  top: calc(100% - .8rem);
  right: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 20rem;
  background-color: $cl-white;
  border: .1rem solid $cl-grey--lighter;
  box-shadow: .0rem .1rem .4rem rgba($cl-shadow, .08), .0rem .6rem 1.2rem rgba($cl-shadow, .1);
  border-radius: .6rem;
  transform: translate3d(0, 1rem, 0);
  transition: {
    property: opacity, visibility, transform;
    duration: 250ms;
  }

  @include min(s) {
    display: none;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  .mobileDropdownButton:first-child {
    padding-top: 2rem;
  }

  .mobileDropdownButton:last-child {
    padding-bottom: 2rem;
  }
}

.mobileDropdownDivider {
  width: calc(100% - 3.2rem);
  margin: 0 1.6rem;
  height: .1rem;
  background-color: $cl-grey--lighter;
}

.mobileDropdownButton {
  cursor: pointer;
  position: relative;
  width: 100%;
  color: $cl-black--light;
  text-align: left;
  padding: 1.2rem 1.6rem;
}
