@import 'styles/colors';
@import 'styles/variables';
@import 'styles/grid';
@import '../Form/Form.module.scss';

.autocomplete {
  position: relative;
  @include max(m) {
    width: 100%;
    display: flex;
  }
}

.secondary {

  .input {

    @include max(s) {
      border: .1rem solid $cl-grey--border;
      border-radius: .6rem;
    }
  }
}

.tertiary {

  .input {

    @include max(m) {
      height: 4.8rem;
      margin: .8rem 1.6rem;
      border-right: .1rem solid $cl-grey--border;
      border-left: .1rem solid $cl-grey--border;
    }
  }

  @include max(m) {
    border-bottom: .1rem solid $cl-grey--border;
  }

  .dropdown {

    @include max(m) {
      top: 100%;
      left: 0;
      width: 100vw;
      border-radius: 0;
      margin-top: -0.1rem;
      border-bottom-right-radius: 1.2rem;
      border-bottom-left-radius:  1.2rem;
      border: none;
      box-shadow: none;
    }
  }
}

.autocomplete--loading {

  &::after {
    content: '';
    position: absolute;
    top: .1rem;
    right: .1rem;
    bottom: .1rem;
    display: block;
    width: 4.8rem;
    background: url('/images/icons/search-throbber.svg') no-repeat, linear-gradient(90deg, rgba($cl-white, 0), $cl-white 1.6rem);
    background-position: calc(100% - 1.2rem) 50%, 50%;
    pointer-events: none;
    @include max(m) {
      top: 2.5rem;
      right: 2rem;
      height: 1.6rem
    }
  }
}

.input {
  @extend .input;
  width: 100%;
  padding-left: 4rem;
  background: url('/images/icons/location.svg') 1.5rem center $cl-white no-repeat;
  border-right: none;
  border-radius: 1.2rem 0 0 1.2rem;
  outline: none;
  // border: none;
  &.search {
    background: url('/images/icons/search-black.svg') 1.5rem center no-repeat;
  }
  @include min(s) {
    min-width: 9rem;
  }
}

.input--user-location {
  background: url('/images/icons/location-user--blue.svg') 1.5rem center no-repeat;
  background-color: white;
  &::placeholder {
    color: $cl-secondary;
  }
}

.input--user-location-loading {
  background: url('/images/icons/search-throbber.svg') 1.5rem 1.4rem no-repeat;
  background-color: white;
}

.dropdown {
  visibility: hidden;
  z-index: 20;
  position: absolute;
  top: calc(100% + .8rem);
  left: 0;
  width: 26.4rem;
  padding: .8rem;
  border-radius: 1.2rem;
  background-color: $cl-white;
  box-shadow: 0 .1rem .4rem rgba($cl-shadow, .08), 0 .6rem 1.2rem rgba($cl-shadow, .1);

  @include max(s) {
    padding: .8rem 1.6rem;
  }
}

.dropdown--is-visible {
  visibility: visible;
}

.dropdown-item {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 4rem;
  border-radius: .6rem;
  padding: 0 1.2rem;
  color: $cl-grey--dark;
  transition: background-color 200ms, color 200ms;
  cursor: pointer;

  span {
    color: $cl-black--light;
    transition: color 200ms;
  }

  &:hover {
    background-color: $cl-grey--lightest;
  }

  img {
    margin-right: .8rem;
  }
}

.dropdown-item--active {
  background-color: $cl-primary;
  color: $cl-white;

  span {
    color: $cl-white;
  }

  &:hover {
    background-color: $cl-primary--light;
  }
}

.dropdown-item--user-location {
  color: $cl-secondary;
}

.dropdown-item--empty {
  pointer-events: none;
}

.overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 8rem;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  transition: visibility 250ms, opacity 250ms;
}

.overlayBackground {
  background-color: rgba($cl-black, .4);
}

.overlayVisible {
  visibility: visible;
  opacity: 1;
}

.disable-user-location {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  width: 4rem;
  height: 4.8rem;
  cursor: pointer;

  &:hover {
    opacity: .5;
  }

  img {
    margin: auto;
  }

  @include max(m) {
    display: block;
    width: 5rem;
    height: 6.8rem;
  }
}

.disable-user-location--loading {
  color: $cl-grey--darkest;
}
