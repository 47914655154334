@import 'styles/variables';
@import 'styles/colors';

.default {
  appearance: none;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  padding: 1.5rem;
  color: $cl-white;
  font-size: 1.6rem;
  font-weight: $font-weight--bold;
  line-height: 1;
  text-decoration: none;
  background: none;
  border: none;
  border: .1rem solid rgba($cl-shadow, .1);
  border-radius: 1.2rem;
  transition: background-color 200ms, color 200ms, border-color 200ms;

  img + span {
    margin-left: .8rem;
  }

  span + img {
    margin-left: .8rem;
  }

  &:disabled {
    cursor: default;
    background-color: $cl-grey--lighter;
    border-color: $cl-grey--lighter;
    color: $cl-white;

    &:hover,
    &:focus,
    &:active {
      background-color: $cl-grey--lighter;
      border-color: $cl-grey--lighter;
    }
  }
}

.primary {
  background-color: $cl-primary;

  &:hover,
  &:focus {
    background-color: $cl-primary--light;
  }

  &:active {
    background-color: $cl-primary;
  }
}

.secondary {
  background-color: $cl-secondary--darker;

  &:hover,
  &:focus {
    background-color: $cl-secondary--dark;
  }

  &:active {
    background-color: $cl-secondary--darker;
  }
}

.inverted {
  color: $cl-secondary--darker;
  background-color: $cl-white;
  border: .1rem solid $cl-grey;

  &:hover,
  &:focus {
    background-color: $cl-grey--lightest;
  }

  &:active {
    background-color: $cl-white;
  }
}

.error {
  color: $cl-secondary--darker;
  background-color: $cl-white;
  border: .1rem solid $cl-grey;

  img {
    transition: filter 200ms;
  }

  &:not([disabled]) {
    &:hover,
    &:focus {
      color: $cl-white;
      background-color: $cl-error;
      border-color: $cl-error;

      img {
        filter: brightness(100);
      }
    }
  }

  &[disabled] {
    img {
      filter: brightness(100);
    }
  }

  &:active {
    background-color: $cl-error;
  }
}

.transparent {
  color: $cl-secondary--darker;
  background-color: transparent;
  border-color: $cl-grey--darkest;

  &:hover,
  &:focus {
    background-color: transparent;
    border-color: $cl-secondary--darker;
  }

  &:active {
    background-color: $cl-white;
  }
}

.spaced {
  margin: 0 .8rem;
}

.text {
  color: $cl-secondary;
  font-weight: $font-weight--book;
  padding: 0;
  border: 0;
  box-shadow: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.small {
  padding: .7rem;
  border-radius: .4rem;
}

.without-border {
  border: none;
}
