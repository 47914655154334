@import 'styles/colors';
@import 'styles/variables';
@import 'styles/grid';
@import 'styles/button';

.modalTitle {
  margin-bottom: .8rem;
  padding-right: 2.4rem;
  font-size: 2.4rem;
  font-weight: $font-weight--bold;
  line-height: 1.33;
  color: $cl-secondary--darker;
}

.modalTitleSecondary {
  margin-bottom: 3.2rem;
  color: $cl-secondary--darker;
  text-align: center;
}

.modalText {
  margin-bottom: 1.6rem;
  font-size: 1.8rem;
  line-height: 1.78;
  color: $cl-black--light;

  &:last-child {
    margin-bottom: 0;
  }

  + .formRow {
    margin-top: 3.2rem;
  }
}

.formInput {
  appearance: none;
  box-sizing: border-box;
  max-height: 4.8rem;
  padding: 1.2rem 1.6rem;
  border: .1rem solid $cl-grey--lighter;
  border-radius: .6rem;
  font-size: 1.6rem;
  line-height: 1.5;
  background: $cl-white;
}

.formRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6rem;

  &:not(.formRowCheckbox) {
    + .formRowCheckbox {
      margin-top: 3.2rem;
    }
  }

  a {
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
}

.formRowAction {
  margin-top: 3.2rem;
}

.formRowLast {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 3.2rem 0 0;

  .errorMessage,
  .successMessage {
    margin-top: .6rem;
  }
}

.formRowLabel {
  display: flex;
  color: $cl-black--light;
  margin-bottom: .4rem;
  line-height: 1.5;

  a {
    margin-left: auto;
  }
}

.formFooter {
  margin: 0 0 .8rem;
  color: $cl-grey--darker;
  line-height: 1.75;
  text-align: center;
}

.errorMessage {
  display: block;
  margin-top: 1.6rem;
  color: $cl-error;
}

.successMessage {
  display: block;
  margin-top: 1.6rem;
  color: $cl-success;
}

.buttonWide {
  width: 100%;
}

.divider {
  position: relative;
  width: 100%;
  text-align: center;
  margin: 2.4rem 0;

  span {
    z-index: 2;
    position: relative;
    color: $cl-grey;
    padding: 0 1.6rem;
    background-color: $cl-white;
  }

  &::after {
    content: "";
    z-index: 1;
    position: absolute;
    top: 50%;
    display: block;
    width: 100%;
    height: .1rem;
    background-color: $cl-grey--lighter;
  }
}

.strengthBar {
  margin-top: .3rem;

  > div > div {
    height: .4rem !important;
    border-radius: .2rem;
  }
}
