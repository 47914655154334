@import 'variables';
@import 'functions';
@import 'colors';
@import 'reset';
@import 'typography';
@import 'grid';
@import 'button';
@import 'mixins';
@import 'backgrounds';
@import 'table';

@import './vendor/react-day-picker';
@import './vendor/swiper';

button,
input {
  font-family: $font-body;
}

html,
body,
#__next {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  min-height: 100%;
}

html {
  @media print {
    font-size: 8px;
  }
}

body {
  font-family: $font-body;
  font-weight: $font-weight--book;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

a {
  color: $cl-secondary;
  cursor: pointer;
}

@media print {
  .no-print {
    display: none;
  }
}
