@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/grid';

.primary {
  overflow: hidden;
  z-index: 2;
  position: absolute;
  top: 7.2rem;
  left: 0;
  width: 100%;
  max-height: 0;
  margin-right: 4.8rem;
  font-size: 1.8rem;
  transition: max-height 300ms;
  background-color: $cl-white;

  @include min(m) {
    position: relative;
    top: 0;
    max-height: unset;
    background-color: transparent;
  }

  &.visible {
    max-height: 100vh;
  }
}

.list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;

  @include min(m) {
    flex-direction: row;
  }
}

.anchor {
  display: block;
  padding: .8rem 0;
  margin: 0 2.4rem;
  color: $cl-black;
  font-size: 1.6rem;
  text-decoration: none;
  white-space: nowrap;

  @include max(m) {
    margin: 0;
    padding: 2.4rem;
  }
}

.selected {
  @include max(m) {
    color: $cl-primary;
  }

  @include min(m) {
    border-bottom: .1rem solid;
  }
}
