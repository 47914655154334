@import '../../../styles/variables';
@import '../../../styles/grid';
@import '../../../styles/mixins';

.container {
  @extend .container;
}

.grid {
  @extend .grid;
  margin: 0;

  @include max(m) {
    row-gap: 3.2rem;
  }
}

.primary {
  margin-top: 5rem;
  .item {
    @extend .col-m4;
    padding: 4rem 2.4rem;
    text-align: center;
    border-radius: .6rem;

    &:nth-child(1) {
      background-color: $cl-secondary--lighter;
    }

    &:nth-child(2) {
      background-color: $cl-primary--lightest;
    }

    &:nth-child(3) {
      background-color: #D9F9F1;
    }
  }

  .header {
    flex-direction: column;
    align-items: center;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.4rem;
    height: 6.4rem;
    border-radius: 50%;
    background-color: $cl-white;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;

  @include min(m) {
    flex-wrap: nowrap;
  }
}

.header {
  display: flex;
  width: 100%;
  margin-bottom: .8rem;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 100%;
}

.heading {
  width: 100%;
}

.icon,
.counter {
  margin-bottom: 1.6rem;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: $cl-secondary--darker;
  font-size: 6.4rem;
  font-weight: $font-weight--black;

  @include min(m) {
    justify-content: flex-start;
  }

  span {
    color: $cl-primary;
    font-size: 4.8rem;
    padding-left: .8rem;
  }
}

.description {
  width: 100%;
  font-size: 1.8rem;
  line-height: 1.778;
}
