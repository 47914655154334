.button {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 1.5rem;
  color: $cl-white;
  font-size: 1.6rem;
  font-weight: $font-weight--bold;
  line-height: 1;
  text-decoration: none;
  border: none;
  transition: background-color 200ms;
  border: .1rem solid rgba($cl-secondary--dark, .1);
  border-radius: 1.2rem;

  span + img {
    margin-right: .8rem;
  }

  img + span {
    margin-left: .8rem;
  }
}

.button--primary {
  background-color: $cl-primary;

  &:hover,
  &:focus {
    background-color: $cl-primary--light;
  }

  &:active {
    background-color: $cl-primary;
  }
}

.button--secondary {
  background-color: $cl-secondary--darker;

  &:hover,
  &:focus {
    background-color: $cl-secondary--dark;
  }

  &:active {
    background-color: $cl-secondary--darker;
  }
}

.button--inverted {
  color: $cl-secondary--darker;
  background-color: $cl-white;

  &:hover,
  &:focus {
    background-color: $cl-grey--lightest;
  }

  &:active {
    background-color: $cl-white;
  }
}

.button--ghost {
  color: $cl-secondary--darker;
  background: $cl-white;
  border: .1rem solid $cl-grey--lighter;

  &:hover,
  &:focus {
    background-color: $cl-grey--lightest;
  }

  &:active {
    background-color: $cl-white;
  }
}
