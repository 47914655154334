@import 'styles/colors';
@import 'styles/variables';
@import 'styles/grid';

.intro {
  @extend .col-m10;
  @extend .push-m1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6.4rem;
  text-align: center;

}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20rem;
  position: relative;
  overflow:hidden;
  border-radius: .4rem;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    object-fit:cover;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.6rem .8rem .8rem;
}

.heading {
  margin-bottom: 1.6rem;
  color: $cl-secondary--darker;
}

.description {
  margin-bottom: 1.6rem;
  color: $cl-black--light;
  font-size: 2.4rem;
  line-height: 1.667;
}

.list {
  @extend .grid;
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  @extend .col-s4;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.6rem;
  padding: .8rem;
  background-color: $cl-white;
  box-shadow: 0 .1rem .4rem rgba($cl-shadow, .1);
  border-radius: .6rem;
  cursor: pointer;

  @include min(s) {
    margin-bottom: 0;
  }

  .heading {
    margin-bottom: 0;
  }
}

.location {
  margin-bottom: .8rem;
}

.pricing {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.pricingLabel {
  color: $cl-grey--dark;
  font-size: 1.4rem;
}

.price {
  color: $cl-secondary--darker;
  font-size: 2.4rem;
  font-weight: $font-weight--bold;
}
