@import 'styles/colors';
@import 'styles/variables';
@import 'styles/grid';
@import './Form.module.scss';

.persons {
  z-index: 2;
  position: relative;
  max-height: 4.8rem;
  background-color: $cl-white;

  &.visible {

    .button::after {
      transform: rotate(-180deg);
    }

    .dropdown {
      visibility: visible;
    }
  }

  &.onlyDropdown {
    display: contents;
    .dropdown {
      position: unset;
      @include max(m) {
        border:none;
        border-bottom-right-radius: 1.2rem;
        border-bottom-left-radius:  1.2rem;
        box-shadow: none;
        margin: auto;
        @media (orientation: landscape) {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}

.secondary {
  @include max(s) {
    width: 100%;
  }

  .button {

    @include max(s) {
      width: 100%;
      border: .1rem solid $cl-grey--lightest;
      border-radius: .6rem;
    }
  }

  .dropdown {
    right: 0;
    left: auto;
  }
}

.tertiary {
  .button {
    overflow: hidden;
    text-overflow: ellipsis;

    @include max(s) {
      width: 100%;
      height: 4.8rem;
      padding: 0 0 0 .8rem;
      border: none;
      background: $cl-white;
      border-radius: 0;

      &::after {
        display: none;
      }
    }

    @include max(m) {
      border: none;
    }
  }

  .dropdown {
    right: 0;
    border-radius: 12px;

    @include min(m) {
      left: 50%;
      transform: translateX(-50%);
    }

    // &.showed {
    //   position: unset;
    // }
    @include max(s) {
      position: fixed;
      top: 6.4rem + 4.8rem;
      width: 100vw;
      border-radius: 0;
    }
  }

  + .overlay {
    top: 0;
  }
}

.button {
  @extend .select;
  position: relative;
  cursor: pointer;
  width: 100%;
  padding-left: 4rem;
  text-align: left;
  white-space: nowrap;
  border-right: none;
  border-radius: 0;
  background: url("/images/icons/person.svg") left 1.5rem center no-repeat;

  &::after {
    content: "";
    position: absolute;
    top: calc(50% - .4rem);
    right: 1.8rem;
    width: 1.3rem;
    height: .8rem;
    background: url("/images/icons/chevron-down.svg") center no-repeat;
    transition: transform 250ms;
  }
  @include max(s) {
    border-top-right-radius:    1.2rem;
    border-bottom-right-radius: 1.2rem;
  }
}

.persons--no-button {
  width: 100%;

  .button {
    border-right: .1rem solid $cl-grey--lighter;
    border-radius: 0 1.2rem 1.2rem 0;
  }
}

.dropdown {
  visibility: hidden;
  position: absolute;
  top: calc(100% + .8rem);
  left: -.8rem;
  width: 30.4rem;
  padding: 1.6rem .8rem 0;
  background-color: $cl-white;
  border: .1rem solid $cl-grey--lightest;
  box-shadow: 0 .1rem .4rem rgba($cl-shadow, .08), 0 .6rem 1.2rem rgba($cl-shadow, .1);
  border-radius: .6rem;

  @include max(s) {
    right: 0;
    left: auto;
  }
}

.heading {
  margin: 0 .8rem 1.6rem;
}

.label {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  margin: 0 .8rem 1.6rem;
  color: $cl-secondary--darker;
  font-weight: $font-weight--bold;
  background-color: $cl-grey--lightest;
  border-radius: .8rem;
  @include max(m) {
    height: 4.8rem;
    width: 4.8rem;
  }

  &:focus,
  &:hover {
    background-color: $cl-grey--lightest;
  }

  &.checked {
    color: $cl-white;
    background-color: $cl-primary;
  }

  .input {
    position: absolute;
    visibility: hidden;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include max(m) {
    @media (orientation: landscape) {
      flex-wrap: nowrap;
      flex-direction: center;
    }
  }
}

.overlay {
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 8rem;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  transition: visibility 250ms, opacity 250ms;
}

.overlayBackground {
  background-color: rgba($cl-black, .4);
}

.overlayVisible {
  visibility: visible;
  opacity: 1;
}

.unSelected {
  color: $cl-grey--dark;
}
