@import 'styles/colors';
@import 'styles/variables';
@import 'styles/grid';
@import 'styles/button';

@keyframes scaleIn {
  from {
    transform: scale(0);
  } to {
    transform: scale(1);
  }
}

.modalHeader {
  min-height: 12.8rem;
  margin: -4.8rem -3.2rem 1.6rem;
  padding: 3.2rem 3.2rem 1.6rem;
}

.modalHeaderIcon {
  z-index: 20;
  position: relative;
  display: block;
  margin: 0 auto;
  animation: scaleIn 200ms forwards cubic-bezier(0, .55, .45, 1);
}

.modalHeaderBackground {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}

.modalTitle {
  margin-bottom: 2.4rem;
  color: $cl-secondary--darker;
  font-size: 3.2rem;
  font-weight: $font-weight--bold;
  line-height: 1.25;
  text-align: center;
}

.modalText {
  margin-bottom: 1.6rem;
  font-size: 1.8rem;
  line-height: 1.56;
  color: $cl-black--light;

  &:last-child {
    margin-bottom: 0;
  }

  + .formRow {
    margin-top: 3.2rem;
  }
}

.modalTextCentered {
  @extend .modalText;
  text-align: center;
  margin-bottom: 3.2rem;
}

.formRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6rem;

  &:not(.formRowCheckbox) {
    + .formRowCheckbox {
      margin-top: 3.2rem;
    }
  }

  a {
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
}

.formInput {
  appearance: none;
  box-sizing: border-box;
  max-height: 4.8rem;
  padding: 1.2rem 1.6rem;
  border: .1rem solid $cl-grey--lighter;
  border-radius: .6rem;
  font-size: 1.6rem;
  line-height: 1.5;
  background: $cl-white;
}

.errorMessage,
.successMessage {
  display: block;
  margin-bottom: 1.6rem;
}

.errorMessage {
  color: $cl-error;
}

.successMessage {
  color: $cl-success;
}

.formTerms {
  margin: 2.4rem 0 .8rem;
  padding: 0 .8rem;
  color: $cl-grey--darker;
  font-size: 1.4rem;
  line-height: 1.75;
  text-align: center;
}

.formRowLabel {
  display: flex;
  color: $cl-black--light;
  margin-bottom: .4rem;
  line-height: 1.5;

  a {
    margin-left: auto;
  }
}

.formRowLast {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 3.2rem 0 0;

  .errorMessage,
  .successMessage {
    margin-top: .6rem;
  }
}

.formFooter {
  margin: 0 0 .8rem;
  color: $cl-grey--darker;
  line-height: 1.75;
  text-align: center;
}

.buttonWide {
  width: 100%;
  margin-bottom: 1.6rem;
}

.strengthBar {
  margin-top: .3rem;

  > div > div {
    height: .4rem !important;
    border-radius: .2rem;
  }
}
