@import 'styles/colors';
@import 'styles/variables';
@import 'styles/grid';

.primary {
  position: relative;
}

.content {
  @extend .col-m6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 3.2rem;

  @include min(m) {
    margin-bottom: 0;
  }
}

.media {
  @extend .col-m6;
  @extend .push-m6;

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.offset {
    margin-right: -50%;

    .image {
      justify-content: flex-end;
    }
  }
}

.heading {
  margin-bottom: 1.6rem;
  color: $cl-secondary--darker;
}

.description {
  margin-bottom: 1.6rem;
  color: $cl-black--light;
  font-size: 2.4rem;
  line-height: 1.667;
}
