// TODO: remove shouldn't be global
.table-holder {
  margin: 0 -.8rem; // TODO: remove minus
  overflow-x: auto;
}

.table {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
}

.table-row {
  display: table-row;
  border-radius: .4rem;

  &:first-child {
    .table-cell {
      padding-top: 0;
    }
  }
}

.table-cell {
  display: table-cell;
  padding: .8rem;

  &:first-child {
    border-radius: .6rem 0 0 .6rem;
  }

  &:last-child {
    border-radius: 0 .6rem .6rem 0;
  }
}

.table--amenities {
  .table-row:not(:first-child) {
    &:hover {
      .table-cell {
        background-color: $cl-grey--lightest;
      }
    }
  }

  .table-cell:first-child {
    width: 100%;
    white-space: nowrap;
  }

  .table-cell:not(:first-child) {
    min-width: 12rem;
    text-align: center;
    vertical-align: bottom;
  }

  label {
    justify-content: center;
  }

  span {
    display: none;
  }
}
