@import '../../../styles/variables';
@import '../../../styles/grid';

.container {
  @extend .container;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list {
  @extend .col-m8;
  @extend .push-m2;
  margin: 0;
  padding: 0;
  list-style: none;
  .list-expanded > .wrapper {
    max-height: 300vh;
    display: contents;
  }
}

.item {
  margin-bottom: 1.6rem;
  color: $cl-black--lighter;
  box-shadow: 0 0 0 .1rem $cl-grey--lightest;
  border-radius: .6rem;
  overflow: hidden;
}

.expanded {

  .wrapper {
    max-height: 100vh;
  }

  .button::after {
    transform: rotate(180deg);
  }
}

.expand-button-container {
  padding-top: 2.4rem;
  text-align: center;
  button {
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
  }
}

.button {
  cursor: pointer;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2.4rem;
  border: none;
  font-family: 'Circular';
  font-size: 1.6rem;
  font-weight: $font-weight--bold;
  text-align: left;
  background-color: $cl-white;

  &::after {
    content: '';
    flex-shrink: 0;
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 1.6rem;
    background: url('/images/icons/chevron.svg') center no-repeat;
    transition: transform 200ms;
  }
}

.answer {
  margin: 0 2.4rem;
  padding: 1.6rem 0;
  font-size: 1.6rem;
  line-height: 1.75;
  box-shadow: 0 .1rem 0 0 $cl-grey--lightest inset;
}

.wrapper {
  overflow: hidden;
  max-height: 0;
  transition: max-height 200ms;
}
