@import 'styles/colors';
@import 'styles/variables';
@import 'styles/grid';

.grid {
  @extend .grid;
  margin: -6.4rem 0;

  @include min(m) {
    margin: -12rem 0;
  }
}

.image {
  @extend .col-m6;
  background-position: center top;
  width: 100%;
  height: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('/images/quote@2x.png');

  @include max(m) {
    background-image: url('/images/quote-small@2x.png');
    height: 350px;
  }

  @include max(s) {
    height: 250px;
  }

  @include max(xs) {
    height: 204px;
  }
}

.content {
  @extend .col-m5;
  @extend .push-m7;
  padding: 10.4rem 0;

  @include max(m) {
    padding: 2rem 0 4rem;
  }

  &::before {
    content: "";
    display: block;
    width: 5rem;
    height: 3.8rem;
    margin-bottom: 4rem;
    background: url('/images/quote.svg') center no-repeat;

    @include max(m) {
      margin-bottom: 2.4rem;
    }
  }
}

.quote {
  margin-bottom: 2.4rem;
  color: $cl-black--light;
  font-size: 2.4rem;
  line-height: 1.5;
  @include max(m) {
    font-size: 1.8rem;
  }
}

.author {
  color: $cl-black--light;
  font-weight: $font-weight--bold;
}
